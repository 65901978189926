<template>
	<div class="place">
		<div class="fullview" v-if="loading">
			<div class="loading-spacer"></div>
			<vue-loading v-if="loading" type="spin" color="#333" :size="{ width: '300px', height: '300px' }"></vue-loading>
		</div>
		<div class="header">
			<h1>測定点編集</h1>
		</div>
		<div class="body">
			<b-form inline>
				<div class="form-group">
				<div class="admin-block">
					<b-row>
						<b-col>
							<label for="kanriId">管理番号</label>
							<b-form-input
							id="kanriId"
							v-model="form.id"
							></b-form-input>
						</b-col>
					</b-row>
					<b-row>
						<b-col>
							<label for="district">地区</label>
							<b-form-input
							id="district"
							placeholder="地区"
							v-model="form.district"
							></b-form-input>
						</b-col>
					</b-row>
					<b-row>
						<b-col>
							<label for="name">名称</label>
							<b-form-input
							disabled
							id="name"
							placeholder="名称"
							v-model="form.name"
							></b-form-input>
						</b-col>
					</b-row>
					<b-row>
						<b-col>
							<label for="address">所在地</label>
							<b-form-input
							id="address"
							placeholder="所在地"
							v-model="form.address"
							></b-form-input>
						</b-col>
					</b-row>
					<b-row>
						<b-col cols="3">
							<label for="lat">緯度</label>
							<b-form-input
							disabled
							id="lat"
							v-model="form.position.lat"
							></b-form-input>
						</b-col>
						<b-col cols="3">
							<label for="lng">経度</label>
							<b-form-input
							disabled
							id="lng"
							v-model="form.position.lng"
							></b-form-input>
						</b-col>
					</b-row>
					<b-row>
						<b-col cols="3">
							<label for="waterlevel_pointkey">水位計 (pointkey)</label>
							<b-form-input
							disabled
							id="waterlevel_pointkey"
							v-model="form.pointkey"
							></b-form-input>
						</b-col>
						<b-col cols="3">
							<label for="rain_pointkey">雨量計 (pointkey)</label>
							<b-form-input
							id="rain_pointkey"
							v-model="form.rain_pointkey"
							></b-form-input>
						</b-col>
					</b-row>
				</div>
				<div class="admin-block">
					<h2>水位しきい値設定</h2>
					<b-row class="indent">
						<b-col cols="2"></b-col>
						<b-col cols="2" class="bold">指定色</b-col>
						<b-col cols="3" class="bold">表示名</b-col>
						<b-col class="bold">常時満水位からの距離</b-col>
					</b-row>
					<b-row class="indent" align-v="center">
						<b-col cols="2" class="wl-name">
							水位A 
						</b-col>
						<b-col cols="2">
							<div class="color-block" :style="'background-color: #000'"></div>
						</b-col>
						<b-col cols="3">
							<b-form-input
							id="level_a_name"
							placeholder="表示名"
							v-model="form.alertThresholdNames[0]"
							></b-form-input>
						</b-col>
						<b-col>
							<b-form-input
							id="level_a_value"
							placeholder=""
							v-model="form.alertThresholds[0]"
							class = "w-50 inline"
							></b-form-input> m
						</b-col>
					</b-row>
					<b-row class="indent" align-v="center">
						<b-col cols="2" class="wl-name">
							水位B
						</b-col>
						<b-col cols="2">
							<div class="color-block" :style="'background-color: #AA17A9'"></div>
						</b-col>
						<b-col cols="3">
							<b-form-input
							id="level_b_name"
							placeholder="表示名"
							v-model="form.alertThresholdNames[1]"
							></b-form-input>
						</b-col>
						<b-col>
							<b-form-input
							id="level_b_value"
							placeholder=""
							v-model="form.alertThresholds[1]"
							class = "w-50 inline"
							></b-form-input> m
						</b-col>
					</b-row>
					<b-row class="indent" align-v="center">
						<b-col cols="2" class="wl-name">
							水位C
						</b-col>
						<b-col cols="2">
							<div class="color-block" :style="'background-color: #FC281C'"></div>
						</b-col>
						<b-col cols="3">
							<b-form-input
							id="level_c_name"
							placeholder="表示名"
							v-model="form.alertThresholdNames[2]"
							></b-form-input>
						</b-col>
						<b-col>
							<b-form-input
							id="level_c_value"
							placeholder=""
							v-model="form.alertThresholds[2]"
							class = "w-50 inline"
							></b-form-input> m
						</b-col>
					</b-row>
					<b-row class="indent" align-v="center">
						<b-col cols="2" class="wl-name">
							水位D
						</b-col>
						<b-col cols="2">
							<div class="color-block" :style="'background-color:#F2E733'"></div>
						</b-col>
						<b-col cols="3">
							<b-form-input
							id="level_d_name"
							placeholder="表示名"
							v-model="form.alertThresholdNames[3]"
							></b-form-input>
						</b-col>
						<b-col>
							<b-form-input
							id="level_d_value"
							placeholder=""
							v-model="form.alertThresholds[3]"
							class = "w-50 inline"
							></b-form-input> m
						</b-col>
					</b-row>
				</div>
				<div class="admin-block">
					<h2>溜池諸元</h2>
					<b-row>
						<b-col cols="3">
							<label for="fulllevel">常時満水位 (m)</label>
							<b-form-input
							id="fulllevel"
							v-model="form.fulllevel"
							></b-form-input>
						</b-col>
						<b-col cols="3">
							<label for="pondBottomArea">池底面積 (㎡)</label>
							<b-form-input
							id="pondBottomArea"
							v-model="form.pondBottomArea"
							></b-form-input>
						</b-col>
						<b-col cols="3">
							<label for="fulllevelArea">満水面積 (㎡)</label>
							<b-form-input
							id="fulllevelArea"
							v-model="form.fulllevelArea"
							></b-form-input>
						</b-col>
						<b-col cols="3">
							<label for="topEdgeArea">上端面積 (㎡)</label>
							<b-form-input
							id="topEdgeArea"
							v-model="form.topEdgeArea"
							></b-form-input>
						</b-col>
					</b-row>
					<b-row>
						<b-col cols="3">
							<label for="tsutsumiKo">堤高 (m)</label>
							<b-form-input
							id="tsutsumiKo"
							v-model="form.tsutsumiKo"
							></b-form-input>
						</b-col>
						<b-col cols="3">
							<label for="kozuibakiSuishin">洪水吐水深 (m)</label>
							<b-form-input
							id="kozuibakiSuishin"
							v-model="form.kozuibakiSuishin"
							></b-form-input>
						</b-col>
						<b-col cols="3">
							<label for="kozuibakiHaba">洪水吐幅 (m)</label>
							<b-form-input
							id="kozuibakiHaba"
							v-model="form.kozuibakiHaba"
							></b-form-input>
						</b-col>
						<b-col cols="3">
							<label for="ryuryoKeisu">流量係数</label>
							<b-form-input
							id="ryuryoKeisu"
							v-model="form.ryuryoKeisu"
							></b-form-input>
						</b-col>
					</b-row>
					<b-row>
						<b-col cols="3">
							<label for="souChosuiRyo">総貯水量 (㎥)</label>
							<b-form-input
							id="souChosuiRyo"
							v-model="form.souChosuiRyo"
							></b-form-input>
						</b-col>
						<b-col cols="3">
							<label for="slitTsutsumiKo">スリット堤高 (m)</label>
							<b-form-input
							id="slitTsutsumiKo"
							v-model="form.slitTsutsumiKo"
							></b-form-input>
						</b-col>
						<b-col cols="3">
							<label for="slitKirikomiHukasa">スリット切込深 (m)</label>
							<b-form-input
							id="slitKirikomiHukasa"
							v-model="form.slitKirikomiHukasa"
							></b-form-input>
						</b-col>
						<b-col cols="3">
							<label for="slitSuishin">スリット水深 (m)</label>
							<b-form-input
							id="slitSuishin"
							v-model="form.slitSuishin"
							></b-form-input>
						</b-col>
					</b-row>
					<b-row>
						<b-col cols="3">
							<label for="slitMansuii">スリット満水位 (m)</label>
							<b-form-input
							id="slitMansuii"
							v-model="form.slitMansuii"
							></b-form-input>
						</b-col>
						<b-col cols="3">
							<label for="slitHaba">スリット幅 (m)</label>
							<b-form-input
							id="slitHaba"
							v-model="form.slitHaba"
							></b-form-input>
						</b-col>
						<b-col cols="3">
							<label for="slitRyuryoKeisu">スリット流量係数</label>
							<b-form-input
							id="slitRyuryoKeisu"
							v-model="form.slitRyuryoKeisu"
							></b-form-input>
						</b-col>
						<b-col cols="3">
							<label for="kozuibakiHabaWithoutSlit">スリットを除いた洪水吐幅 (m)</label>
							<b-form-input
							id="kozuibakiHabaWithoutSlit"
							v-model="form.kozuibakiHabaWithoutSlit"
							></b-form-input>
						</b-col>
					</b-row>
					<b-row>
						<b-col cols="3">
							<label for="shusuiKoKanjoTanshin">取水口管上端深 (m)</label>
							<b-form-input
							id="shusuiKoKanjoTanshin"
							v-model="form.shusuiKoKanjoTanshin"
							></b-form-input>
						</b-col>
						<b-col cols="3">
							<label for="shusuiKoKanChokkei">取水口管直径 (m)</label>
							<b-form-input
							id="shusuiKoKanChokkei"
							v-model="form.shusuiKoKanChokkei"
							></b-form-input>
						</b-col>
						<b-col cols="3">
							<label for="shusuiKoSodoKeisu">取水口粗度係数</label>
							<b-form-input
							id="shusuiKoSodoKeisu"
							v-model="form.shusuiKoSodoKeisu"
							></b-form-input>
						</b-col>
						<b-col cols="3">
							<label for="shusuiKoKanroKobai">取水口管路勾配</label>
							<b-form-input
							id="shusuiKoKanroKobai"
							v-model="form.shusuiKoKanroKobai"
							></b-form-input>
						</b-col>
					</b-row>
					<b-row>
						<b-col cols="3">
							<label for="shusuiKoRyuryoKeisu">取水口流量係数</label>
							<b-form-input
							id="shusuiKoRyuryoKeisu"
							v-model="form.shusuiKoRyuryoKeisu"
							></b-form-input>
						</b-col>
						<b-col cols="3">
							<label for="c">定数C</label>
							<b-form-input
							id="c"
							v-model="form.c"
							></b-form-input>
						</b-col>
						<b-col cols="3">
							<label for="regressionBottomCoefficient">回帰式 係数 (下限)</label>
							<b-form-input
							id="regressionBottomCoefficient"
							v-model="form.regressionBottomCoefficient"
							></b-form-input>
						</b-col>
						<b-col cols="3">
							<label for="regressionBottomOffset">回帰式 定数項 (下限)</label>
							<b-form-input
							id="regressionBottomOffset"
							v-model="form.regressionBottomOffset"
							></b-form-input>
						</b-col>
					</b-row>
					<b-row>
						<b-col cols="3">
							<label for="regressionCoefficient">回帰式 係数 (中央値)</label>
							<b-form-input
							id="regressionCoefficient"
							v-model="form.regressionCoefficient"
							></b-form-input>
						</b-col>
						<b-col cols="3">
							<label for="vha1">V-H式 定数a1</label>
							<b-form-input
							id="vha1"
							v-model="form.vh[0]"
							></b-form-input>
						</b-col>
						<b-col cols="3">
							<label for="vha2">V-H式 定数a2</label>
							<b-form-input
							id="vha2"
							v-model="form.vh[1]"
							></b-form-input>
						</b-col>
						<b-col cols="3">
							<label for="vha3">V-H式 定数a3</label>
							<b-form-input
							id="vha3"
							v-model="form.vh[2]"
							></b-form-input>
						</b-col>
					</b-row>
					<b-row>
						<b-col cols="3">
							<label for="vha4">V-H式 定数a4</label>
							<b-form-input
							id="vha4"
							v-model="form.vh[3]"
							></b-form-input>
						</b-col>
						<b-col cols="3">
							<label for="vha5">V-H式 定数a5</label>
							<b-form-input
							id="vha5"
							v-model="form.vh[4]"
							></b-form-input>
						</b-col>
						<b-col cols="3">
							<label for="vhb">V-H式 定数b</label>
							<b-form-input
							id="vhb"
							v-model="form.vh[5]"
							></b-form-input>
						</b-col>
					</b-row>
				</div>
				<div class="last-block">
					<div>
						<b-button variant="secondary" @click="$router.push('/admin')" >更新せずに戻る</b-button>
						<b-button variant="primary" @click="onClickSave" >更新</b-button>
					</div>
				</div>
			</div>
			</b-form>
		</div>
	</div>
</template>

<script>
import { VueLoading } from 'vue-loading-template'

export default {
	components: {
		VueLoading
	},
	computed: {
	},
	data() {
		return {
			form: {},
			loading: false
		};
	},
	created() {
		this.form = Object.assign({}, this.$store.getters.getPlaceByIndex(this.$route.params.id));
	},
	methods: {
		async onClickSave() {
			this.loading = true;
			if (!this.form.id) {
				alert("管理番号が不正か入力されていないため、設定を保存できません");
				this.loading = false;
				return;
			}
			if (this.checkDuplicateId(this.form.id)) {
				alert("管理番号が他の測定点のものと重複しているため、設定を保存できません");
				this.loading = false;
				return;
			}
			for (let i = 0; i < this.form.alertThresholds.length; i++) {
				if (this.checkNumber(this.form.alertThresholds[i])) {
					alert("水位しきい値の値が数値ではないため、設定を保存できません");
					this.loading = false;
					return;
				} else {
					this.form.alertThresholds[i] = parseFloat(this.form.alertThresholds[i]);
				}
			}
			if (this.checkNumber(this.form.fulllevel)) {
					alert("常時満水位の値が数値ではないため、設定を保存できません");
					this.loading = false;
					return;
			} else {
				this.form.fulllevel = parseFloat(this.form.fulllevel);
			}
			if (this.checkNumber(this.form.pondBottomArea)) {
					alert("池底面積の値が数値ではないため、設定を保存できません");
					this.loading = false;
					return;
			} else {
				this.form.pondBottomArea = parseFloat(this.form.pondBottomArea);
			}
			if (this.checkNumber(this.form.fulllevelArea)) {
					alert("満水面積の値が数値ではないため、設定を保存できません");
					this.loading = false;
					return;
			} else {
				this.form.fulllevelArea = parseFloat(this.form.fulllevelArea);
			}
			if (this.checkNumber(this.form.topEdgeArea)) {
					alert("上端面積の値が数値ではないため、設定を保存できません");
					this.loading = false;
					return;
			} else {
				this.form.topEdgeArea = parseFloat(this.form.topEdgeArea);
			}
			if (this.checkNumber(this.form.tsutsumiKo)) {
					alert("堤高の値が数値ではないため、設定を保存できません");
					this.loading = false;
					return;
			} else {
				this.form.tsutsumiKo = parseFloat(this.form.tsutsumiKo);
			}
			if (this.checkNumber(this.form.kozuibakiSuishin)) {
					alert("洪水吐水深の値が数値ではないため、設定を保存できません");
					this.loading = false;
					return;
			} else {
				this.form.kozuibakiSuishin = parseFloat(this.form.kozuibakiSuishin);
			}
			if (this.checkNumber(this.form.kozuibakiHaba)) {
					alert("洪水吐幅の値が数値ではないため、設定を保存できません");
					this.loading = false;
					return;
			} else {
				this.form.kozuibakiHaba = parseFloat(this.form.kozuibakiHaba);
			}
			if (this.checkNumber(this.form.ryuryoKeisu)) {
					alert("流量係数の値が数値ではないため、設定を保存できません");
					this.loading = false;
					return;
			} else {
				this.form.ryuryoKeisu = parseFloat(this.form.ryuryoKeisu);
			}
			if (this.checkNumber(this.form.souChosuiRyo)) {
					alert("総貯水量の値が数値ではないため、設定を保存できません");
					this.loading = false;
					return;
			} else {
				this.form.souChosuiRyo = parseFloat(this.form.souChosuiRyo);
			}
			if (this.checkNumber(this.form.slitTsutsumiKo)) {
					alert("スリット堤高の値が数値ではないため、設定を保存できません");
					this.loading = false;
					return;
			} else {
				this.form.slitTsutsumiKo = parseFloat(this.form.slitTsutsumiKo);
			}
			if (this.checkNumber(this.form.slitKirikomiHukasa)) {
					alert("スリット切込深の値が数値ではないため、設定を保存できません");
					this.loading = false;
					return;
			} else {
				this.form.slitKirikomiHukasa = parseFloat(this.form.slitKirikomiHukasa);
			}
			if (this.checkNumber(this.form.slitSuishin)) {
					alert("スリット水深の値が数値ではないため、設定を保存できません");
					this.loading = false;
					return;
			} else {
				this.form.slitSuishin = parseFloat(this.form.slitSuishin);
			}
			if (this.checkNumber(this.form.slitMansuii)) {
					alert("スリット満水位の値が数値ではないため、設定を保存できません");
					this.loading = false;
					return;
			} else {
				this.form.slitMansuii = parseFloat(this.form.slitMansuii);
			}
			if (this.checkNumber(this.form.slitHaba)) {
					alert("スリット幅の値が数値ではないため、設定を保存できません");
					this.loading = false;
					return;
			} else {
				this.form.slitHaba = parseFloat(this.form.slitHaba);
			}
			if (this.checkNumber(this.form.slitRyuryoKeisu)) {
					alert("スリット流量係数の値が数値ではないため、設定を保存できません");
					this.loading = false;
					return;
			} else {
				this.form.slitRyuryoKeisu = parseFloat(this.form.slitRyuryoKeisu);
			}
			if (this.checkNumber(this.form.kozuibakiHabaWithoutSlit)) {
					alert("スリットを除いた洪水吐き幅の値が数値ではないため、設定を保存できません");
					this.loading = false;
					return;
			} else {
				this.form.kozuibakiHabaWithoutSlit = parseFloat(this.form.kozuibakiHabaWithoutSlit);
			}
			if (this.checkNumber(this.form.shusuiKoKanjoTanshin)) {
					alert("取水口管上端深の値が数値ではないため、設定を保存できません");
					this.loading = false;
					return;
			} else {
				this.form.shusuiKoKanjoTanshin = parseFloat(this.form.shusuiKoKanjoTanshin);
			}
			if (this.checkNumber(this.form.shusuiKoKanChokkei)) {
					alert("取水口管直径の値が数値ではないため、設定を保存できません");
					this.loading = false;
					return;
			} else {
				this.form.shusuiKoKanChokkei = parseFloat(this.form.shusuiKoKanChokkei);
			}
			if (this.checkNumber(this.form.shusuiKoSodoKeisu)) {
					alert("取水口粗度係数の値が数値ではないため、設定を保存できません");
					this.loading = false;
					return;
			} else {
				this.form.shusuiKoSodoKeisu = parseFloat(this.form.shusuiKoSodoKeisu);
			}
			if (this.checkNumber(this.form.shusuiKoKanroKobai)) {
					alert("取水口管路勾配の値が数値ではないため、設定を保存できません");
					this.loading = false;
					return;
			} else {
				this.form.shusuiKoKanroKobai = parseFloat(this.form.shusuiKoKanroKobai);
			}
			if (this.checkNumber(this.form.shusuiKoRyuryoKeisu)) {
					alert("取水口流量係数の値が数値ではないため、設定を保存できません");
					this.loading = false;
					return;
			} else {
				this.form.shusuiKoRyuryoKeisu = parseFloat(this.form.shusuiKoRyuryoKeisu);
			}
			if (this.checkNumber(this.form.c)) {
					alert("定数Cの値が数値ではないため、設定を保存できません");
					this.loading = false;
					return;
			} else {
				this.form.c = parseFloat(this.form.c);
			}
			if (this.checkNumber(this.form.regressionBottomCoefficient)) {
					alert("回帰式 係数 (下限)の値が数値ではないため、設定を保存できません");
					this.loading = false;
					return;
			} else {
				this.form.regressionBottomCoefficient = parseFloat(this.form.regressionBottomCoefficient);
			}
			if (this.checkNumber(this.form.regressionBottomOffset)) {
					alert("回帰式 定数項 (下限)の値が数値ではないため、設定を保存できません");
					this.loading = false;
					return;
			} else {
				this.form.regressionBottomOffset = parseFloat(this.form.regressionBottomOffset);
			}
			if (this.checkNumber(this.form.regressionCoefficient)) {
					alert("回帰式 係数 (中央値)の値が数値ではないため、設定を保存できません");
					this.loading = false;
					return;
			} else {
				this.form.regressionCoefficient = parseFloat(this.form.regressionCoefficient);
			}
			for (let i = 0; i < this.form.vh.length; i++) {
				if (this.checkNumber(this.form.vh[i])) {
					alert("V-H式定数の値が数値ではないため、設定を保存できません");
					this.loading = false;
					return;
				} else {
					this.form.vh[i] = parseFloat(this.form.vh[i]);
				}
			}
			let result = await this.$store.dispatch("setPlaceParameters", { index: this.$route.params.id, data: this.form});
			if (result) {
				alert("設定を保存しました");
				this.$router.push({ name: "AdminHome", params: {} }).catch(() => {});
			}
			this.loading = false;
		},
		checkDuplicateId(kanriId) {
			for (let i = 0; i < this.$store.state.places.summaryData.length; i++) {
				if (this.$route.params.id == i) continue;
				let summary = this.$store.state.places.summaryData[i];
				if (summary.id == kanriId) return true;
			}
			return false;
		},
		checkNumber(n) {
			return !Number.isFinite(parseFloat(n));
		}
	}
};
</script>
<style>
.fullview {
  width: 100%;
  height: 100%;
  background-color:rgba(0,0,0,0.5);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
}
.loading-spacer {
  height: 30%;
}
</style>